// import React from 'react';
// import Layout from '../components/layout';
// import PageContainer from '../components/Containers/PageContainer';
// import Container from '@material-ui/core/Container';
import { useEffect } from 'react'
import { navigate } from 'gatsby'

const NotFound = () => {
  useEffect(() => {
    navigate('/')
  }, []);
  return null;
  // return (
  //   <Layout>
  //     <PageContainer>
  //     <Container maxWidth="lg" style={{height: 120}}>
  //       <h2 style={{paddingTop: 40}}>404 error, page not found</h2>
  //     </Container>
  //     </PageContainer>
  // </Layout>
  // );
};

export default NotFound;
